@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap");

* {
  box-sizing: border-box !important;
  transition: ease all 0.5s;
}

html {
  scroll-behavior: smooth;
}

body {
  color: #666666;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  line-height: 1.80857;
  font-weight: normal;
  overflow-x: hidden;
}

.form-group > span {
  color: #f00;
}
.mb-24 {
  margin-bottom: 24px;
}
button:is([type="submit"]) {
  width: 25%;
  float: right;
  background-color: #353433;
  color: #fefefe;
  text-align: center;
  padding: 12px 0px;
  font-size: 18px;
  border-radius: 10px;
  margin-right: 30px;
}

/* .send_bt a {
    color: #fefefe;
} */
button:is(:hover) {
  color: #000;
  background: #f8ca11;
}

a {
  color: #1f1f1f;
  text-decoration: none !important;
  outline: none !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0;
  font-weight: normal;
  position: relative;
  padding: 0 0 10px 0;
  font-weight: normal;
  line-height: normal;
  color: #111111;
  margin: 0;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 13px;
}

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #212121;
  text-decoration: none !important;
  opacity: 1;
}

button:focus {
  outline: none;
}

ul,
li,
ol {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

p {
  margin: 20px;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
}

a {
  color: #222222;
  text-decoration: none;
  outline: none !important;
}

a,
.btn {
  text-decoration: none !important;
  outline: none !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

img {
  max-width: 100%;
  height: auto;
}

:focus {
  outline: 0;
}

.paddind_bottom_0 {
  padding-bottom: 0 !important;
}

.btn-custom {
  margin-top: 20px;
  background-color: transparent !important;
  border: 2px solid #ddd;
  padding: 12px 40px;
  font-size: 16px;
}

.lead {
  font-size: 18px;
  line-height: 30px;
  color: #767676;
  margin: 0;
  padding: 0;
}

.form-control:focus {
  border-color: #ffffff !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}

.navbar-form input {
  border: none !important;
}

.badge {
  font-weight: 500;
}

blockquote {
  margin: 20px 0 20px;
  padding: 30px;
}

button {
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.full {
  width: 100%;
}

.layout_padding {
  padding-top: 90px;
  padding-bottom: 0px;
}

.header_section {
  width: 100%;

  backdrop-filter: saturate(180%) blur(20px);
  background: var(--localnav-background-stuck, rgba(26, 26, 26, 0.8));
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  z-index: 01;
}

.logo {
  width: 100%;
  max-width: 160px;
  text-align: center;
}

.menu_text {
  width: auto;
  display: block;
}

.menu_text ul {
  margin: 0px;
  padding: 0px;
  display: flex;
}

.menu_text li {
  padding-right: 75px;
  font-size: 18px;
  color: #ffffff;
  padding-top: 12px;
}

.menu_text li a {
  color: #ffffff;
}

.menu_text li a:hover {
  color: #f8ca11;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

.overlay a {
  padding: 0px;
  text-decoration: none;
  font-size: 22px;
  color: #f1f1f1;
  display: block;
  transition: 0.3s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.toggle_menu {
  top: -3px;
  position: relative;
  left: 15px;
}

.banner_section {
  width: 100%;

  background-color: #1a1a1a;
  height: auto;
  padding-top: 90px;
}

.book_now {
  width: 70%;
  float: right;
  background-color: #f8ca11;
  height: auto;
  padding: 10px;
}

.book_text {
  width: 100%;

  font-size: 40px;
  color: #fbfaf6;
  text-align: center;
}

.call_text {
  width: 100%;

  font-size: 40px;
  color: #1a1a1a;
  text-align: center;
}

.image_1 {
  width: 80%;
  float: right;
  margin-top: 50px;
}

.booking_text {
  width: 100%;

  font-size: 26px;
  color: #fbfaf6;
  text-align: center;
}

.contact_bg {
  width: 100%;
  padding-top: 0;
  padding: 30px;
  background-color: transparent;
}

.request_text {
  width: 100%;

  font-size: 30px;
  color: #fff;
  border-bottom: 0px solid#49bcfe;
  padding-top: 20px;
  text-align: center;
}

.email-bt {
  background: #e1e1e1;
  border-bottom: 1px solid #fffcf4;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  color: #000 !important;
  width: 95%;
  height: 30px;
  font-size: 20px;
  padding: 20px 20px 20px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  margin: 0 auto;
  display: block;
}

.taxis_section {
  width: 100%;
  /*  */
  background-color: #161c1e;
  height: auto;
  /* margin-top: 90px; */
  padding-bottom: 50px;
}

.our_text {
  width: 100%;

  text-align: center;
  font-size: 50px;
  color: #fefefe;
  font-weight: bold;
}

.taxis_section_2 {
  width: 100%;
  /*  */
}

.taxi_main {
  width: 100%;

  background-color: #fefefe;
  height: auto;
  margin-top: 70px;
}

.round_1 {
  width: 21%;
  margin: 0 auto;
  font-size: 24px;
  color: #fefefe;
  height: 70px;
  background: #f8ca11;
  display: block;
  text-align: center;
  border-radius: 60px;
  padding-top: 12px;
  position: relative;
  top: -35px;
}

.carol_text {
  width: 100%;

  text-align: center;
  color: #2b3436;
  font-size: 24px;
}

.reader_text {
  width: 100%;

  text-align: center;
  color: #2b3436;
  font-size: 20px;
  margin-left: 0px;
}

input.email-bt::placeholder {
  color: #000;
}

.images_2 {
  width: 100%;

  text-align: center;
}

.ride_section {
  width: 100%;
}

.ride_text {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: #2b3436;
  font-size: 50px;
  margin-left: 0px;
  border-bottom: 1px solid #000;
}

.ride_main {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.ride_section_2 {
  width: 100%;
}

.image_3 {
  width: 100%;
}

.cabe_text {
  width: 100%;

  font-size: 24px;
  color: #2b3436;
  margin-top: 20px;
}

.long_text {
  width: 100%;

  font-size: 16px;
  color: #2b3436;
  margin-left: 0px;
}

.book_bt a {
  font-size: 18px;
  color: #fefefe;
  background-color: #f8ca11;
  text-align: center;
  padding: 10px 0px;
  margin-top: 30px;
  display: inline-block;
  width: 150px;
}

.book_bt a {
  color: #fefefe;
}

.book_bt a:hover {
  color: #fff;
  background: #000;
}

.secure_text {
  width: 100%;

  font-size: 24px;
  color: #2b3436;
  margin-top: 20px;
  text-align: right;
}

.long_text_2 {
  width: 100%;

  font-size: 16px;
  color: #2b3436;
  margin-left: 0px;
  text-align: right;
}

.book_bt_2 a {
  width: 150px;
  float: right;
  font-size: 18px;
  color: #fefefe;
  background-color: #f8ca11;
  text-align: center;
  padding: 10px 0px;
  margin-top: 30px;
}

.book_bt_2 a {
  color: #fefefe;
}

.book_bt_2 a:hover {
  color: #fff;
  background: #000;
}

.location_sectin {
  width: 100%;

  background-image: url(assets/images/img-6.png);
  height: auto;
  background-size: 100%;
  padding-bottom: 30px;
}

.location_main {
  width: 100%;
}

.download_text {
  width: 100%;

  font-size: 50px;
  color: #24292b;
  padding-left: 53px;
}

.image_7 {
  width: 90%;
}

.account_text {
  width: 100%;

  font-size: 28px;
  color: #ebebeb;
  font-weight: bold;
  padding: 25px 0px;
}

.image-icon {
  width: 100%;
}

.fb_text {
  font-size: 12pt;
  color: #ebebeb;
  padding-left: 16px;
}

.fb_text a {
  color: #fffcf4;
}

.fb_text a:hover {
  color: #fecf3d;
}

.adderess_text {
  width: 100%;

  font-size: 28px;
  color: #ebebeb;
  font-weight: bold;
  padding: 25px 0px;
}

.footer_section_2 {
  width: 100%;

  padding-top: 40px;
}

.section_footer {
  width: 100%;

  background-color: #292828;
  height: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 90px;
}

.ipsum_text {
  font-size: 12pt;
  color: #ebebeb;
  margin-left: 0px;
}

.email_text {
  width: 100%;

  font-size: 12pt;
  color: #eceaea;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  border: 0px;
}

.subscribr_bt {
  width: 60%;

  font-size: 16pt;
  color: #eceaea;
  background-color: #fecf3d;
  padding: 5px 0px;
  border: 0px;
  text-align: center;
  margin-top: 20px;
  font-weight: 600;
  border-radius: 5px;
}

.copyright_section {
  width: 100%;

  background-color: #ffffff;
  height: auto;
}

.copyright {
  width: 100%;

  font-size: 18px;
  color: #000;
  text-align: center;
  margin-left: 0;
}

.copyright a {
  color: #000;
}

.copyright a:hover {
  color: #fecf3d;
}
.flex-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.for_mobile {
  display: none;
}
.menu-icon-btn {
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}
.btn.focus,
.btn:focus {
  box-shadow: none !important;
}
@media (max-width: 1000px) {
  .for_mobile {
    display: flex;
    justify-content: flex-end;
  }
  .menu_text {
    display: none;
  }
  .menu-icon-btn:hover {
    background-color: inherit;
  }
  .menu_text_mobile {
    position: fixed;
    right: 0;
    left: 0;
    top: 67px;
    backdrop-filter: saturate(180%) blur(20px);
    background: var(--localnav-background-stuck, rgba(26, 26, 26, 0.8));
  }
  .menu_text_mobile ul {
    margin: 0;
    padding: 15px;
  }
  .menu_text_mobile ul li {
    padding: 5px;
  }
  .menu_text_mobile ul li:hover {
    padding: 5px;
    background: #f8ca11;
  }
  .menu_text_mobile ul li:hover a {
    color: #000;
  }
  .menu_text_mobile ul li a {
    color: #fff;
  }
}
